export default {
    /**
     * 取url中参数
     * @param name
     * @returns {string | null}
     */
    getUrlKey(name) {
        return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null
    },



    //数组去空
    removeEmpty: (arr) => {
        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === "" || typeof(arr[i]) === "undefind") {
                arr.splice(i, 1);
                i--;
            }
        }
        return arr;
    },

    //数组去重
    removeRepeat: (arr) => {
        let res = [];
        let json = {};
        for (let i = 0; i < arr.length; i++) {
            if (!json[arr[i]]) {
                res.push(arr[i]);
                json[arr[i]] = 1;
            }
        }
        return res;
    }

}